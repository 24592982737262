'use client'

import * as Sentry from '@sentry/nextjs'

import {AppNodeContextProvider} from 'AppContext'
import {Blocks} from 'blocks/blocks'
import {Hero} from 'blocks/hero/hero'
import {Layout} from 'layout/layout'
import {useEffect} from 'react'

export default function ErrorPage({error}: {error: Error & {digest?: string}}) {
	useEffect(() => {
		Sentry.captureException(error)
	}, [error])

	return (
		<AppNodeContextProvider node={null}>
			<Layout>
				<Blocks blocks={[]}>
					<Hero title="500 - Error: Something went wrong" />
				</Blocks>
			</Layout>
		</AppNodeContextProvider>
	)
}
